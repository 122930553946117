import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["contentSwitch", "contentSection", "contentSubSection", "turboFrame"];

  connect() {
    this.updateContent();

    document.addEventListener("turbo:submit-end", () => {
      this.setUrlParamsOnTurboFrame();
    });

    document.addEventListener("turbo:frame-render", () => {
      this.updateContent();
    });
  }

  switchContent(event) {
    event.preventDefault();

    const paramKey = event.currentTarget.dataset.paramKey;
    const targetId = event.currentTarget.id;
    const url = new URL(window.location);

    if (paramKey == targetId) {
      url.searchParams.set(paramKey, "true");
    } else {
      url.searchParams.delete(paramKey);
    }

    window.history.pushState({}, "", url);

    this.updateContent();
  }

  updateContent() {
    const urlParams = new URLSearchParams(window.location.search);

    this.contentSectionTargets.forEach((section) => {
      this.updateSection(section, urlParams);
    });

    this.contentSubSectionTargets.forEach((section) => {
      this.updateSection(section, urlParams);
    });
  }

  updateSection(section, urlParams) {
    const param = urlParams.get(section.dataset.paramKey) === "true";

    this.toggleVisibility(section, section.dataset.paramKey, param);
    this.updateStyles(section.dataset.paramKey, param);
  }

  toggleVisibility(element, param, paramState) {
    if (element.dataset.contentType == param) {
      paramState ? element.classList.remove("hidden") : element.classList.add("hidden");
    } else {
      paramState ? element.classList.add("hidden") : element.classList.remove("hidden");
    }
  }

  updateStyles(param, paramState) {
    const element = this.contentSwitchTargets.find((contentSwitch) => {
      return contentSwitch.dataset.paramKey == param;
    });

    const styleClasses = [
      "bg-white",
      "border-0",
      "sm:border-b-2",
      "sm:border-forest-600",
      "hover:bg-white",
      "active",
    ];

    element.querySelectorAll("li").forEach((li) => {
      if (li.dataset.paramKey == li.dataset.contentType) {
        if (paramState) {
          li.classList.add(...styleClasses);
        } else {
          li.classList.remove(...styleClasses);
        }
      } else {
        if (paramState) {
          li.classList.remove(...styleClasses);
        } else {
          li.classList.add(...styleClasses);
        }
      }
    });
  }

  setUrlParamsOnTurboFrame() {
    const params = new URLSearchParams(window.location.search).toString();
    const formattedParams = params ? `?${params}` : "";

    if (this.turboFrameTarget) {
      const src = this.turboFrameTarget.getAttribute("src");
      if (src) {
        this.turboFrameTarget.setAttribute("src", `${src.split("?")[0]}${formattedParams}`);
      }
    }
  }
}
