import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { frame: String, path: String };

  connect() {
    console.log("Hello, Stimulus!", this.frameValue);
    let filters = window.location.search
    console.log(filters)
    if (filters.includes("priority")) {
      document.getElementsByName("radio-priority")[0].checked = true;
      this.toggleCategories("priority")
    } else {
      document.getElementsByName("radio-category")[0].checked = true;
      this.toggleCategories("category")
    }
  }

  updateFrame(event) {
    this.toggleCategories("");
    this.toggleOrganise(event.target.value);
    let param = `${this.pathValue}?${event.target.value}=true`
    let frame = document.getElementById(this.frameValue);
    frame.src = param;
    frame.reload();
  }

  toggleCategories(filter) {
    document.querySelectorAll('[id^="checkbox-"]').forEach((element) => {
      if (element.id != `checkbox-${filter}`) {
        element.classList.toggle("hidden");
      }
    });
  }

  toggleOrganise(filter) {
    document.querySelectorAll('[name^="radio-"]').forEach((element) => {
      if (element.name != `radio-${filter}`) {
        element.checked = false;
      }
    });
  }
}
